import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/rWdnbNS-6Po">
    <SEO title="The Love of Christmas - Christmas Comes" />
  </Layout>
)

export default SermonPost
